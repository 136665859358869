<template>
  <div class="product-form product-form--basic-info row">
    <div v-if="isLoaded">
      <div class="col-24" v-if="$props.formType != 'add'">
        <div class="product-form__header d-flex align-items-center">
          <div
            :class="{ 'is-hidden': isHidden }"
            @click.prevent="isHidden = !isHidden"
          >
            <icon
              icon="plus"
              v-if="!isHidden"
              icon-classes="icon__plus--large icon--black icon--rotated"
            />
            <icon
              icon="plus"
              v-else
              icon-classes="icon__plus--large icon--black"
            />
          </div>
          <span class="product-form__header-title">Informacje podstawowe</span>
        </div>
      </div>

      <transition name="transition-slide">
        <div class="col-24" v-if="!isHidden">
          <div class="row">
            <div class="col-24 col-xl">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Nazwa</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.name"
                  @change="onInputChange()"
                />
              </div>
            </div>

            <div class="col-24 col-xl-auto flex-shrink">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Status</div>
                <label
                  :for="'check-status-' + localProduct.id"
                  class="product-form__checkbox"
                >
                  <input
                    :id="'check-status-' + localProduct.id"
                    class="product-form__checkbox--input"
                    type="checkbox"
                    v-model="localProduct.status"
                    @change="onInputChange()"
                  />
                  <span
                    class="product-form__checkbox-checkmark"
                    v-text="localProduct.status ? 'Aktywny' : 'Nieaktywny'"
                  ></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-24 col-xl-24">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Opis</div>
                <TextEditor
                  :object="localProduct"
                  fieldName="description"
                  @onInputChange="onInputChange"
                ></TextEditor>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-24 col-xl-8">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Index</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.index"
                  @change="onInputChange()"
                />
              </div>
            </div>

            <div class="col-24 col-xl-8">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Ukryty index</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.hidden_index"
                  @change="onInputChange()"
                />
              </div>
            </div>

            <div class="col-24 col-xl-8">
              <div class="product-form__input-wrap">
                <div class="product-form__title">EAN</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.ean"
                  @change="onInputChange()"
                />
              </div>
            </div>

            <div class="col-24 col-xl-4">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Cena netto</div>
                <div class="d-flex align-items-center">
                  <input
                    class="product-form__input"
                    type="text"
                    @change="onInputChange()"
                    v-model="localProduct.price_netto"
                  />
                  <span class="product-form__symbol">
                    {{ localProduct.language.currency }}
                  </span>
                </div>
              </div>
            </div>

            <div class="col-24 col-xl-4">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Cena brutto</div>
                <div class="d-flex align-items-center">
                  <input
                    class="product-form__input"
                    type="text"
                    v-model="localProduct.price_brutto"
                    @change="onInputChange()"
                  />
                  <span class="product-form__symbol">
                    {{ localProduct.language.currency }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-24 col-xl-6">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Brand</div>
                <treeselect
                  v-if="brands.length > 0"
                  v-model="localProduct.brand_id"
                  :multiple="false"
                  :options="brands"
                  placeholder=""
                  :normalizer="normalizer"
                  :clearable="false"
                  @input="onBrandChange()"
                />
                <div
                  v-else
                  class="product-form__note"
                  v-text="
                    isLoading
                      ? 'Trwa wczytywanie...'
                      : 'Brak zdefiniowanych brandów w bazie.'
                  "
                ></div>
              </div>
            </div>

            <div class="col-24 col-xl-6">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Kategoria</div>
                <treeselect
                  v-if="categories.length > 0"
                  v-model="localProduct.category_id"
                  :multiple="false"
                  :options="categories"
                  placeholder=""
                  :normalizer="normalizer"
                  :clearable="false"
                  :default-expand-level="Infinity"
                  @input="onInputChange()"
                />
                <div
                  v-else
                  class="product-form__note"
                  v-text="
                    isLoading
                      ? 'Trwa wczytywanie...'
                      : localProduct.brand_id
                      ? 'Brak zdefiniowanych kategorii w bazie.'
                      : 'Należy najpierw wybrać brand.'
                  "
                ></div>
              </div>
            </div>

            <div class="col-24 col-xl-6">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Kolekcja</div>
                <treeselect
                  v-if="collections.length > 0"
                  v-model="localProduct.collection_id"
                  :multiple="false"
                  :options="collections"
                  placeholder=""
                  :normalizer="normalizer"
                  :clearable="false"
                  @input="onInputChange()"
                />
                <div
                  v-else
                  class="product-form__note"
                  v-text="
                    isLoading
                      ? 'Trwa wczytywanie...'
                      : 'Brak zdefiniowanych kolekcji w bazie.'
                  "
                ></div>
              </div>
            </div>

            <div class="col-24 col-xl-6">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Aranżacja</div>
                <treeselect
                  v-if="arrangements.length > 0"
                  v-model="localProduct.arrangements"
                  :multiple="true"
                  :options="arrangements"
                  placeholder=""
                  :normalizer="normalizerMultiple"
                  :clearable="false"
                  :limit="3"
                  :limitText="limitText"
                  @input="onInputChange()"
                />
                <div
                  v-else
                  class="product-form__note"
                  v-text="
                    isLoading
                      ? 'Trwa wczytywanie...'
                      : 'Brak zdefiniowanych aranżacji w bazie.'
                  "
                ></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-24 col-xl-8">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Wykończenie</div>
                <treeselect
                  v-if="finishes.length > 0"
                  v-model="localProduct.finishes"
                  :multiple="true"
                  :options="finishes"
                  placeholder=""
                  :normalizer="normalizerMultiple"
                  :clearable="false"
                  :limit="3"
                  :limitText="limitText"
                  @input="onInputChange()"
                />
                <div
                  v-else
                  class="product-form__note"
                  v-text="
                    isLoading
                      ? 'Trwa wczytywanie...'
                      : 'Brak zdefiniowanych wykończeń w bazie.'
                  "
                ></div>
              </div>
            </div>

            <div class="col-24 col-xl-8">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Kolor</div>
                <treeselect
                  v-if="colors.length > 0"
                  v-model="localProduct.colors"
                  :multiple="true"
                  :options="colors"
                  placeholder=""
                  :normalizer="normalizerMultiple"
                  :clearable="false"
                  :limit="3"
                  :limitText="limitText"
                  @input="onInputChange()"
                />
                <div
                  v-else
                  class="product-form__note"
                  v-text="
                    isLoading
                      ? 'Trwa wczytywanie...'
                      : 'Brak zdefiniowanych kolorów w bazie.'
                  "
                ></div>
              </div>
            </div>

            <div class="col-24 col-xl-8">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Zainstalowane systemy</div>
                <treeselect
                  v-if="systems.length > 0"
                  v-model="localProduct.systems"
                  :multiple="true"
                  :options="systems"
                  placeholder=""
                  :normalizer="normalizerMultiple"
                  :clearable="false"
                  :limit="3"
                  :limitText="limitText"
                  @input="onInputChange()"
                />
                <div
                  v-else
                  class="product-form__note"
                  v-text="
                    isLoading
                      ? 'Trwa wczytywanie...'
                      : 'Brak zdefiniowanych systemów w bazie.'
                  "
                ></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-24 col-xl-18">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Grupa produktów</div>
                <treeselect
                  v-if="localProduct.id"
                  v-model="localProduct.group_id"
                  :normalizer="normalizer"
                  :multiple="false"
                  :async="true"
                  :load-options="loadOptions"
                  :default-options="true"
                  @input="onInputChange()"
                />
                <div
                  v-else
                  class="product-form__note"
                  v-text="
                    isLoading
                      ? 'Trwa wczytywanie...'
                      : 'Brak zdefiniowanych systemów w bazie.'
                  "
                ></div>
              </div>
            </div>
            <div class="col-24 col-xl-6 flex-shrink">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Główny</div>
                <label
                  :for="'group-main-' + localProduct.id"
                  class="product-form__checkbox"
                >
                  <input
                    :id="'group-main-' + localProduct.id"
                    class="product-form__checkbox--input"
                    type="checkbox"
                    v-model="localProduct.group_main"
                    @change="onInputChange()"
                  />
                  <span
                    class="product-form__checkbox-checkmark"
                    v-text="localProduct.group_main ? 'Tak' : 'Nie'"
                  ></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-24 col-xl">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Szerokość</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.width"
                  @change="onInputChange()"
                />
              </div>
            </div>

            <div class="col-24 col-xl">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Wysokość</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.height"
                  @change="onInputChange()"
                />
              </div>
            </div>

            <div class="col-24 col-xl">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Długość</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.length"
                  @change="onInputChange()"
                />
              </div>
            </div>

            <div class="col-24 col-xl">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Objętość</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.volume"
                  @change="onInputChange()"
                />
              </div>
            </div>

            <div class="col-24 col-xl">
              <div class="product-form__input-wrap">
                <div class="product-form__title">Waga</div>
                <input
                  class="product-form__input"
                  type="text"
                  v-model="localProduct.weight"
                  @change="onInputChange()"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-24 col-xl-8">
              <div class="product-form__input-wrap">
                <div class="product-form__title">
                  Szczegóły techniczne - zdjęcie
                </div>

                <input
                  type="file"
                  ref="photosInput"
                  @change="onImageChange"
                  :disabled="isStoring"
                  style="display: none"
                />

                <button
                  v-if="!imagePath"
                  class="button button--icon mb-3"
                  @click="$refs.photosInput.click()"
                  :disabled="isStoring"
                >
                  <icon
                    v-if="!isStoring"
                    icon="plus"
                    icon-classes="button__icon button__icon--left"
                  >
                  </icon>
                  <div
                    v-else
                    class="loader button__icon button__icon--left"
                  ></div>
                  <span class="button__text">Dodaj zdjęcie techniczne</span>
                </button>

                <div class="product-photo" v-if="imagePath">
                  <img :src="imagePath" />

                  <div
                    class="product-photo__actions d-flex align-items-center justify-content-end"
                  >
                    <button
                      class="button-icon button-icon--ml-10 button-icon--bg-white"
                      :disabled="isStoring"
                      @click="$refs.photosInput.click()"
                    >
                      <icon icon="edit" />
                    </button>
                    <button
                      class="button-icon button-icon--ml-10 button-icon--bg-white"
                      :disabled="isStoring"
                      @click="deleteTechnicalPhoto()"
                    >
                      <icon icon="trash" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-24 col-xl-16">
              <div class="product-form__input-wrap">
                <div class="product-form__title">
                  Szczegóły techniczne - opis
                </div>
                <TextEditor
                  :object="localProduct"
                  fieldName="technical_details"
                  @onInputChange="onInputChange"
                ></TextEditor>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>
<script>
import axios from "axios";
import Icon from "../parts/Icon";
import Treeselect from "@riophae/vue-treeselect";
import _ from "lodash";
import TextEditor from "../parts/TextEditor.vue";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";
// const simulateAsyncOperation = (fn) => {
//   setTimeout(fn, 2000);
// };
export default {
  data() {
    return {
      languages: {},
      brands: [],
      localProduct: {
        status: 0,
      },
      changed: false,
      categories: [],
      collections: [],
      colors: [],
      arrangements: [],
      systems: [],
      finishes: [],
      isHidden: false,
      isLoaded: false,
      isLoading: true,
      image: "",
      isStoring: false,
      showModal: false,
      imagePath: false,
      objectToDelete: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.subcategories,
        };
      },
      normalizerMultiple(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  components: {
    Icon,
    Treeselect,
    TextEditor,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  watch: {
    product: {
      handler(newVal) {
        this.localProduct = _.cloneDeep(newVal);

        // status
        this.localProduct.status =
          this.localProduct.status == "1" ? true : false;

        // arrangements
        let arrangements = [];
        this.localProduct.arrangements.forEach((arrangement) => {
          arrangements.push(arrangement.id);
        });
        this.localProduct.arrangements = arrangements;

        // colors
        let colors = [];
        this.localProduct.colors.forEach((arrangement) => {
          colors.push(arrangement.id);
        });
        this.localProduct.colors = colors;

        // finishes
        let finishes = [];
        this.localProduct.finishes.forEach((arrangement) => {
          finishes.push(arrangement.id);
        });
        this.localProduct.finishes = finishes;

        // systems
        let systems = [];
        this.localProduct.systems.forEach((arrangement) => {
          systems.push(arrangement.id);
        });
        this.localProduct.systems = systems;

        // techincal details photo
        if (this.localProduct.technical_details_image_path) {
          this.imagePath = this.getApiPath(
            this.localProduct.technical_details_image_path
          );
        }

        if (
          !this.brands.length ||
          !this.categories.length ||
          !this.collections.length ||
          !this.groups.length ||
          !this.finishes.length ||
          !this.colors.length ||
          !this.arrangements.length
        ) {
          this.getEditProductData();
        }

        this.isLoaded = true;
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.$root.$on("saveProduct", () => {
      this.storeProduct();
    });
  },
  beforeDestroy() {
    this.$root.$off("saveProduct");
  },
  methods: {
    loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH && searchQuery.length > 3) {
        let params = {
          search: searchQuery,
        };
        axios
          .get(API_BASE_URL + "/product-groups", {
            params: params,
          })
          .then((response) => {
            const options = response.data;
            callback(null, options);
          });
      } else if (action === ASYNC_SEARCH) {
        const options = [this.localProduct.group];
        callback(null, options);
      }
    },
    onInputChange() {
      if (this.changed == false) {
        this.$emit("productChanged", true);
        this.changed = true;
      }
    },
    onBrandChange() {
      this.getCategories();
      this.onInputChange();
    },
    async storeProduct() {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.$emit("storingProduct", true);

      let formData = new FormData();
      formData.append("image", this.image);
      formData.append("id", this.localProduct.id);
      formData.append("name", this.localProduct.name);
      formData.append("description", this.localProduct.description);
      formData.append("status", this.localProduct.status ? "1" : "0");
      formData.append("index", this.localProduct.index);
      formData.append("hidden_index", this.localProduct.hidden_index);
      formData.append("ean", this.localProduct.ean);
      formData.append("price_netto", this.localProduct.price_netto);
      formData.append("price_brutto", this.localProduct.price_brutto);
      formData.append("brand_id", this.localProduct.brand_id);
      formData.append("category_id", this.localProduct.category_id);
      formData.append("collection_id", this.localProduct.collection_id);
      formData.append("group_id", this.localProduct.group_id);
      formData.append("group_main", this.localProduct.group_main ? "1" : "0");
      formData.append("arrangements", this.localProduct.arrangements);
      formData.append("colors", this.localProduct.colors);
      formData.append("finishes", this.localProduct.finishes);
      formData.append("systems", this.localProduct.systems);
      formData.append("width", this.localProduct.width);
      formData.append("height", this.localProduct.height);
      formData.append("length", this.localProduct.length);
      formData.append("volume", this.localProduct.volume);
      formData.append("weight", this.localProduct.weight);
      formData.append("technical_details", this.localProduct.technical_details);
      formData.append(
        "technical_details_image_path",
        this.localProduct.technical_details_image_path
      );

      axios
        .post(API_BASE_URL + "/products", formData, config)
        .then((response) => {
          this.$root.$emit("noticesBox", {
            type: "success",
            message: "Poprawnie zapisano dane podstawowe produktu.",
          });
          this.$emit("updateProduct", response.data);
          this.$emit("productChanged", false);
          this.changed = false;
        })
        .catch((error) => {
          this.$emit("storingProduct", false);
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    getEditProductData() {
      this.isLoading = true;
      axios
        .get(API_BASE_URL + "/product-edit")
        .then((response) => {
          this.collections = response.data.collections;
          this.finishes = response.data.finishes;
          this.colors = response.data.colors;
          this.arrangements = response.data.arrangements;
          this.brands = response.data.brands;
          this.systems = response.data.systems;

          if (this.brands) {
            this.brands.forEach((brand) => {
              if (brand.id == this.localProduct.brand_id) {
                if (brand.categories.length > 0) {
                  brand.categories.forEach((item) => {
                    if (item.subcategories.length == 0) {
                      item.subcategories = undefined;
                    } else {
                      this.changeEmptyArrayToUndefinedRecursive(
                        item.subcategories
                      );
                    }

                    this.categories = brand.categories;
                  });
                }
              }
            });
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.$root.$emit("noticesBox", error.response.data);
          this.isLoading = false;
        });
    },
    getCategories() {
      this.isLoading = true;
      axios
        .get(API_BASE_URL + "/product-categories/" + this.localProduct.brand_id)
        .then((response) => {
          response.data.forEach((item) => {
            if (item.subcategories.length == 0) {
              item.subcategories = undefined;
            } else {
              this.changeEmptyArrayToUndefinedRecursive(item.subcategories);
            }
          });

          this.isLoading = false;
          this.categories = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.$emit("noticesBox", error.response.data);
        });
    },
    changeEmptyArrayToUndefinedRecursive(items) {
      let flat = [];

      items.forEach((item) => {
        if (
          Array.isArray(item.subcategories) &&
          item.subcategories.length > 0
        ) {
          flat.push(
            ...this.changeEmptyArrayToUndefinedRecursive(item.subcategories)
          );
        } else {
          item.subcategories = undefined;
          flat.push(item);
        }
      });

      return flat;
    },
    limitText(count) {
      return "+ " + count;
    },
    onImageChange(e) {
      this.image = e.target.files[0];
      this.imagePath = URL.createObjectURL(this.image);
      this.$refs.photosInput.value = "";
      this.onInputChange();
    },
    getApiPath(path) {
      if (path) {
        let timestamp = new Date().getTime();

        return API_PHOTO_URL + path + "?" + timestamp;
      } else return require("../../img/default.png");
    },
    deleteTechnicalPhoto() {
      this.imagePath = false;
      this.localProduct.technical_details_image_path = "delete";
      this.onInputChange();
    },
  },
};
</script>
