<template>

    <div class="files-list">

        <div class="content-top row">
            <div class="col-24 content-top__wrap">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista plików</h1>
                    <div class="content-top__subheading">{{ files.total }}
                        {{ files.total | numeralsText('plik','pliki','plików')}}</div>
                </div>

                <div class="content-top__search simple-search">
                    <input type="text" class="simple-search__input" @input="searchFiles()" placeholder="Wyszukaj..."
                        v-model="search">
                    <button class="simple-search__button" @click="getFiles()">
                        <icon icon="search" />
                    </button>
                </div>
            </div>

            <div v-if="files.data.length > 0" class="col-24 d-flex content-top__icons-wrap">
                <button class="button-icon button-icon--medium button-icon--red" @click.prevent="massDelete()"
                    :disabled="selectedFiles < 1">
                    <icon icon="trash" icon-classes="icon--grey" />
                </button>
            </div>
        </div>
        <table class="items-table" v-if="files.data.length > 0">

            <thead class="items-table__thead">
                <tr class="items-table__tr">
                    <th class="items-table__th">
                        <label for="check-product-toggle" class="checkbox">
                            <input id="check-product-toggle" class="checkbox__input" type="checkbox"
                                v-model="toggleSelectFiles"/>
                            <span class="checkbox__checkmark"></span>
                        </label>
                    </th>
                    <th class="items-table__th"></th>
                    <th class="items-table__th">
                        Nazwa oryginalna
                    </th>
                    <th class="items-table__th">
                        Nazwa wyświetlana
                    </th>
                    <th class="items-table__th">
                        Opis
                    </th>
                    <th class="items-table__th">
                        Ścieżka
                    </th>
                    <th class="items-table__th">
                        Typ
                    </th>
                    <th class="items-table__th pr-0 text-right">akcje</th>
                </tr>
            </thead>

            <tbody class="items-table__tbody">

                <!-- item -->
                <template v-for="file in files.data">
                    <tr class="items-table__tr" v-bind:key="file.id">

                        <td class="items-table__td items-table__td--not-text d-none d-xl-table-cell">
                            <label :for="'check-file-' + file.id" class="checkbox">
                                <input :id="'check-file-' + file.id" class="checkbox__input" type="checkbox"
                                    :value="file.id" v-model="selectedFiles" @keypress.enter.prevent="saveObject()"/>
                                <span class="checkbox__checkmark"></span>
                            </label>
                        </td>

                        <td class="items-table__td d-none d-xl-table-cell">
                            <div class="items-table__icon" v-if="file.type"
                                :style="{ backgroundImage: 'url(' + getApiPath(file.type.icon) + ')'}"></div>
                        </td>

                        <td class="items-table__td">
                            <div class="form-box__mobile-element">Nazwa oryginalna</div>
                            <div class="form-box__content">
                                {{ file.original_name }}
                            </div>
                        </td>

                        <td class="items-table__td">
                            <div class="form-box__mobile-element">Nazwa wyświetlana</div>
                            <div class="form-box__content" v-if="file.display_name">
                                {{ file.display_name }}
                            </div>
                        </td>

                        <td class="items-table__td items-table__td--expand">
                            <div class="form-box__mobile-element">Opis</div>
                            <div class="form-box__content" v-text="(file.description && file.description.length > 72) ? (file.description.substr(0, 72) + '...' ) : file.description"></div>
                        </td>

                        <td class="items-table__td">
                            <div class="form-box__mobile-element">Ścieżka</div>
                            <div class="form-box__content">
                                {{ file.path }}
                            </div>
                        </td>

                        <td class="items-table__td items-table__td--nowrap">
                            <div class="form-box__mobile-element">Typ</div>
                            <div class="form-box__content" v-if="file.type">
                                {{ file.type.name }}
                            </div>
                        </td>

                        <td class="items-table__td pr-0">
                            <div class="form-box__buttons">
                                <button class="button-icon" @click.prevent="editObject(file)">
                                    <icon icon="edit" icon-classes="icon--grey" />
                                </button>

                                <button class="button-icon button-icon--ml-10" @click="deleteFile(file)">
                                    <icon icon="trash" icon-classes="icon--grey" />
                                </button>
                                <!-- Buttons -->
                            </div>
                            <div class="form-box__mobile-element">
                                <label :for="'check-file-' + file.id" class="checkbox">
                                    <input :id="'check-file-' + file.id" class="checkbox__input" type="checkbox"
                                        :value="file.id" v-model="selectedFiles" />
                                    <span class="checkbox__checkmark"></span>
                                </label>
                            </div>
                        </td>

                    </tr>
                </template>
                <!-- end of item -->
            </tbody>
        </table>
        <div v-else class="content">
            Brak wyników.
        </div>

        <!-- Paginations -->
        <pagination :pagination="files" @paginate="getFiles()" :offset="1"></pagination>
        <!-- Paginations -->

        <!-- Modal edit file -->
        <modal :show="showEditModal" size="lg" @close="showEditModal = !showEditModal">
            <div slot="header" class="notice-modal__header--title">
                Edycja pliku
            </div>

            <div slot="body">

                <div class="row">
                    <div class="col-24 mb-4" v-if="editingObject.original_name">
                        <div class="product-form__title">Nazwa oryginalna</div>
                        {{ editingObject.original_name }}
                    </div>

                    <div class="col-24">
                        <div class="product-form__title">Nazwa wyświetlana</div>
                        <input class="product-form__input" type="text" v-model="editingObject.display_name" required />
                    </div>

                    <div class="col-24 mt-4">
                        <div class="product-form__title">Opis</div>
                        <textarea class="product-form__input product-form__input--textarea" v-model="editingObject.description"></textarea>
                    </div>

                    <div class="col-24 mt-4">
                        <div class="product-form__title">Ścieżka</div>
                        {{ editingObject.path }}
                    </div>
                        
                    <div class="col-24 mt-4" v-if="editingObject.type">
                        <div class="product-form__title">Typ</div>
                        <treeselect class="vue-treeselect__control--small" v-model="editingObject.type.id" :multiple="false" :options="fileTypes"  placeholder="" :normalizer="normalizer" :clearable="false"/>
                    </div>

                </div>

            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showEditModal = false" :disabled="isStoring">
                    Anuluj
                </button>
                <button class="button button--small button--ml-10" @click="saveObject()" :disabled="isStoring" v-text="(isStoring) ? 'Zapisuję...' : 'Zapisz'">
                    Zapisz
                </button>
            </div>
        </modal>

        <!-- Modal delete file -->
        <modal :show="showDeleteModal" size="md" @close="showDeleteModal = !showDeleteModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie pliku
            </div>

            <div slot="body">
                <div>Czy na pewno chcesz usunąć plik <strong>{{ objectToDelete.name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showDeleteModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteFile()">
                    Tak
                </button>
            </div>
        </modal>

        <!-- Modal mass delete files -->
        <modal :show="showMassDeleteModal" size="md"
            @close="showMassDeleteModal = !showMassDeleteModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie plików
            </div>

            <div slot="body">
                <div>Czy na pewno chcesz usunąć
                    {{ selectedFiles.length | numeralsText('zaznaczony','zaznaczone','zaznaczone')}}
                    <strong>{{ selectedFiles.length }}</strong>
                    {{ selectedFiles.length | numeralsText('plik','pliki','plików')}} ?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showMassDeleteModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmMassDelete()">
                    Tak
                </button>
            </div>
        </modal>

    </div><!-- // Files list-->

</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;
    import _ from 'lodash';
    import Icon from './parts/Icon';
    import Modal from './parts/Modal';
    import Treeselect from '@riophae/vue-treeselect';

    import Pagination from './parts/Pagination';

    export default {
        data() {
            return {
                isLoading: true,
                files: {
                    total: 0,
                    per_page: 2,
                    from: 1,
                    to: 0,
                    current_page: 1,
                    data: {}
                },
                type_id: 1,
                isStoring: false,
                message: '',
                selectedFiles: [],
                search: '',
                order: 'desc',
                orderBy: 'created_at',
                editingObject: {},
                objectToDelete: {},
                fileTypes: {},
                showMassDeleteModal: false,
                showDeleteModal: false,
                showEditModal: false,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name
                    }
                }
            }
        },
        components: {
            Pagination,
            Icon,
            Modal,
            Treeselect
        },
        created() {
            this.getFileTypes();
        },
        mounted() {
            this.getFiles();
        },
        computed: {
            toggleSelectFiles: {
                get: function () {
                    return this.files.data ? this.selectedFiles.length == this.files.data.length : false;
                },
                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.files.data.forEach(function (file) {
                            selected.push(file.id);
                        });
                    }

                    this.selectedFiles = selected;
                }
            }
        },
        methods: {
            async getFileTypes() {
                this.response = {};
                try {
                    const response = await axios.get(API_BASE_URL + '/files/types');
                    this.fileTypes = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            async getFiles() {
                this.selectedFiles = [];
                try {
                    var params = {
                        'page': this.files.current_page,
                        'order_by': this.orderBy,
                        'per_page': 20,
                        'order': this.order,
                        'lang': this.$store.state.language.current
                    };

                    if (this.search != '') params.search = this.search;

                    const response = await axios.get(API_BASE_URL + '/files', {
                        params: params
                    });
                    this.files = response.data;
                    this.isLoading = false;
                } catch (error) {
                    this.isLoading = false;
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            deleteFile(file) {
                this.showDeleteModal = true;
                this.objectToDelete = file;
            },
            async confirmDeleteFile() {
                this.showDeleteModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/files/' + this.objectToDelete.id);
                    this.getFiles();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            sortObjects() {
                event.target.setAttribute('order', ((this.order == 'asc') ? 'desc' : 'asc'));
                this.orderBy = event.target.getAttribute('order-by');
                this.order = event.target.getAttribute('order');
                this.getFiles();
            },
            searchFiles: _.debounce(function () {
                this.files.current_page = 1;
                this.getFiles()
            }, 400),
            async massDelete() {
                this.showMassDeleteModal = true;
            },
            async confirmMassDelete() {
                try {
                    await axios.post(API_BASE_URL + '/files/delete', {
                        files: this.selectedFiles
                    });
                    this.getFiles();
                    this.showMassDeleteModal = false;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                    this.getFiles();
                }
            },
            getApiPath(path) {
                if (path) {
                    let timestamp = new Date().getTime();

                    return API_PHOTO_URL + path + '?' + timestamp;
                } else return require('../iconfont/default-file-icon.svg');
            },
            editObject(object) {
                this.editingObject = object;
                if(!this.editingObject.type){
                    this.editingObject.type = {};
                }
                this.showEditModal = true;
            },
            async saveObject() {
                try {

                    let data = this.editingObject;
                    data.type_id = (this.editingObject.type) ? this.editingObject.type.id : false;

                    let response = await axios.post(API_BASE_URL + '/files/update', data);
                    this.editingObject = {};
                    this.getFiles();

                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: response.data.message
                    });

                    this.showEditModal = false;

                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            }
        }
    }
</script>