<template>
    <div>
        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista systemów produktowych</h1>
                    <div class="content-top__subheading">{{ objects.length }}
                        {{ objects.length | numeralsText('system', 'systemy', 'systemów') }}
                    </div>
                </div>
                <button class="button button--icon" @click="addObject()">
                    <icon icon="plus" icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                    <span class="button__text">Dodaj nowy system</span>
                </button>
            </div>
        </div>

        <div class="content row">
            <div class="col-24">
                <table class="items-table">
                    <thead class="items-table__thead">
                        <tr class="items-table__tr">
                            <th class="items-table__th">
                                ID
                            </th>
                            <th class="items-table__th">
                                Obrazek
                            </th>
                            <th class="items-table__th" style="min-width: 400px;">
                                Nazwa
                            </th>
                            <th class="items-table__th items-table__th--expand">
                                Opis
                            </th>
                            <th class="items-table__th p-0 text-right">
                                Akcje
                            </th>
                        </tr>
                    </thead>

                    <tbody class="items-table__tbody">
                        <template v-for="object in objects">
                            <tr class="items-table__tr" v-bind:key="object.id">
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Id</div>
                                    {{ object.id }}
                                </td>

                                <td class="items-table__td" style="min-width: 150px;">
                                    <img :src="getApiPath(object.image_path)" />
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Nazwa</div>
                                    {{ object.name }}
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Opis</div>
                                    <div v-html="object.description"></div>
                                </td>

                                <td class="items-table__td items-table__td--button">
                                    <div class="form-box__mobile-element">Akcje</div>
                                    <div class="d-flex justify-content-end">
                                        <button class="button-icon" @click.prevent="editObject(object)">
                                            <icon icon="edit" />
                                        </button>

                                        <button class="button-icon button-icon--ml-10" @click="deleteObject(object)">
                                            <icon icon="trash" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal add/edit -->
        <modal :show="showModal" size="lg" @close="showModal = !showModal">
            <div slot="header" class="notice-modal__header--title">
                <span v-text="(!isEditing) ? 'Dodawanie nowego systemu' : 'Edycja systemu'"></span>
            </div>

            <div slot="body">

                <div class="row">
                    <div class="col-24">
                        <div class="product-form__title">Nazwa</div>
                        <input class="product-form__input" type="text" v-model="object.name" required />
                    </div>

                    <div class="col-24 mt-4">
                        <div class="product-form__title">Obrazek</div>

                        <input type="file" ref="photosInput" @change="onImageChange" :disabled="isStoring"
                            style="display: none;">

                        <button v-if="!imagePath" class="button button--icon mb-3" @click="$refs.photosInput.click()"
                            :disabled="isStoring">
                            <icon v-if="!isStoring" icon="plus" icon-classes="button__icon button__icon--left">
                            </icon>
                            <div v-else class="loader button__icon button__icon--left"></div>
                            <span class="button__text">Dodaj obrazek</span>
                        </button>

                        <div class="product-photo mb-0" v-if="imagePath">
                            <img :src="imagePath" />

                            <div class="product-photo__actions d-flex align-items-center justify-content-end">
                                <button class="button-icon button-icon--ml-10 button-icon--bg-white"
                                    :disabled="isStoring" @click="$refs.photosInput.click()">
                                    <icon icon="edit" />
                                </button>
                                <button class="button-icon button-icon--ml-10 button-icon--bg-white"
                                    :disabled="isStoring" @click="deleteTechnicalPhoto()">
                                    <icon icon="trash" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-24 mt-4">
                        <div class="product-form__title">Opis</div>
                        <div class="product-form__input-wrap tiptap mb-0">
                            <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, focused }">
                                <div class="tiptap__menubar" :class="{ 'is-disabled': !focused }">
                                    <button class="button-icon button-icon--medium"
                                        :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                                        <icon icon="bold" />
                                    </button>
                                    <button class="button-icon button-icon--medium"
                                        :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
                                        <icon icon="italic" />
                                    </button>
                                    <button class="button-icon button-icon--medium"
                                        :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
                                        <icon icon="underline" />
                                    </button>
                                    <button class="button-icon button-icon--medium"
                                        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                                        @click="commands.heading({ level: 2 })">
                                        H2
                                    </button>
                                    <button class="button-icon button-icon--medium"
                                        :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                                        @click="commands.heading({ level: 3 })">
                                        H3
                                    </button>
                                    <button class="button-icon button-icon--medium"
                                        :class="{ 'is-active': isActive.heading({ level: 4 }) }"
                                        @click="commands.heading({ level: 4 })">
                                        H4
                                    </button>
                                    <button class="button-icon button-icon--medium"
                                        :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
                                        <icon icon="ul" />
                                    </button>
                                </div>
                            </editor-menu-bar>
                            <editor-content class="mt-2" :editor="editor" />
                        </div>
                    </div>

                    <div class="col-24 mt-4">
                        <div class="product-form__title">Produkty</div>
                        <treeselect v-if="products.length > 0" v-model="object.products" :limit="0" :multiple="true"
                            :options="products" value-consists-of="ALL_WITH_INDETERMINATE" placeholder=""
                            :clearable="false" :limitText="limitText"/>
                    </div>

                </div>

            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false" :disabled="isStoring">
                    Anuluj
                </button>
                <button class="button button--small button--ml-10" @click="storeObject()" :disabled="isStoring" v-text="(isStoring) ? 'Zapisuję...' : 'Zapisz'">
                    Zapisz
                </button>
            </div>
        </modal>

        <!-- Modal delete -->
        <modal :show="showDeleteModal" size="md" @close="showDeleteModal = !showDeleteModal">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie systemu
            </div>

            <div slot="body">
                <div>Czy na pewno chcesz usunąć system
                    <strong>{{ '#' + objectToDelete.id + ' - ' + objectToDelete.name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showDeleteModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteObject()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;
    import Icon from './../parts/Icon';
    import Modal from './../parts/Modal';
    import Treeselect from '@riophae/vue-treeselect'
    import _ from 'lodash';
    import {
        Editor,
        EditorContent,
        EditorMenuBar
    } from 'tiptap';
    import {
        Heading,
        BulletList,
        ListItem,
        Bold,
        Italic,
        Underline
    } from 'tiptap-extensions';

    export default {
        data() {
            return {
                objects: {},
                object: {},
                products: [],
                showModal: false,
                showDeleteModal: false,
                isEditing: false,
                isStoring: false,
                imagePath: false,
                objectToDelete: {},
                editor: new Editor({
                    content: 'Loading...',
                    onUpdate: ({
                        getJSON,
                        getHTML
                    }) => {

                        let content = getHTML(),
                            json = getJSON().content;

                        if (Array.isArray(json) && json.length === 1 && !json[0].hasOwnProperty(
                                "content")) {
                            content = null;
                        }

                        this.object.description = content;
                        this.$emit("input", content);
                    },
                    extensions: [
                        new BulletList(),
                        new ListItem(),
                        new Bold(),
                        new Italic(),
                        new Underline(),
                        new Heading({
                            levels: [2, 3, 4]
                        })
                    ]
                })
            }
        },
        components: {
            Icon,
            Modal,
            EditorContent,
            EditorMenuBar,
            Treeselect
        },
        async created() {
            this.getObjects();
            this.getCategoriesWithProducts();
        },
        methods: {
            async storeObject() {
                let vm = this;
                let data = this.object;
                this.isStoring = true;

                try {

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    let formData = new FormData();
                    if (data.id) {
                        formData.append('id', data.id);
                    }
                    if (data.name) {
                        formData.append('name', data.name);
                    }
                    if (data.description) {
                        formData.append('description', data.description);
                    }
                    if (data.image_path) {
                        formData.append('image_path', data.image_path);
                    }
                    if (data.image) {
                        formData.append('image', data.image);
                    }
                    if (data.products) {
                        formData.append('products', data.products);
                    }

                    const response = await axios.post(API_BASE_URL + '/product-systems', formData, config);

                    vm.objects = response.data;
                    vm.object = {};
                    vm.showModal = false;
                    vm.isStoring = false;

                } catch (error) {
                    vm.isStoring = false;
                    vm.$root.$emit('noticesBox', error.response.data);
                }
            },
            async getObjects() {
                try {
                    const response = await axios.get(API_BASE_URL + '/product-systems');
                    this.objects = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            async getCategoriesWithProducts() {
                try {
                    const response = await axios.get(API_BASE_URL + '/product-systems/categories-with-products');
                    this.products = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            onImageChange(e) {
                this.object.image = e.target.files[0];
                this.imagePath = URL.createObjectURL(this.object.image);

                this.$refs.photosInput.value = '';
            },
            deleteTechnicalPhoto() {
                this.object.image_path = 'delete';
                this.imagePath = false;
            },
            getApiPath(path) {
                if (path) {
                    let timestamp = new Date().getTime();

                    return API_PHOTO_URL + path + '?' + timestamp;
                } else return require('../../img/default.png');
            },
            editObject(object) {
                this.object = _.cloneDeep(object);
                if (object.image_path) {
                    this.imagePath = this.getApiPath(object.image_path);
                }
                let objectProducts = [];
                this.object.products.forEach((p) => {
                    objectProducts.push(p.id);
                });
                this.object.products = objectProducts;

                this.editor.setContent(object.description, false);
                this.showModal = true;
                this.isEditing = true;
            },
            addObject() {
                this.object = {};
                this.editor.setContent('', false);
                this.imagePath = false;
                this.isEditing = false;
                this.showModal = true;
            },
            async deleteObject(object) {
                this.showDeleteModal = true;
                this.objectToDelete = object;
            },
            limitText(count){
                return 'wybrano: ' + count;
            },
            async confirmDeleteObject() {
                this.showDeleteModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/product-systems/' + this.objectToDelete.id);
                    this.getObjects();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            }
        }
    }
</script>