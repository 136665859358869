import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";

// Components
import Dashboard from "./components/Dashboard.vue";
import ActivityLog from "./components/ActivityLog.vue";
import Login from "./components/Login.vue";
import ResetPassword from "./components/ResetPassword.vue";

// -- Users
import UsersList from "./components/users/UsersList.vue";
import UsersStore from "./components/users/UsersStore.vue";

// -- Account
import AccountEdit from "./components/AccountEdit.vue";

// -- Products
import ProductsList from "./components/ProductsList.vue";
import ProductEdit from "./components/ProductEdit.vue";
import ProductsImport from "./components/ProductsImport.vue";

// -- Dicionaries
import DictionariesList from "./components/dictionaries/DictionariesList.vue";
import DictionariesLanguages from "./components/dictionaries/Languages.vue";
import DictionariesProductBrands from "./components/dictionaries/ProductBrands.vue";
import DictionariesProductSystems from "./components/dictionaries/ProductSystems.vue";
import DictionariesProductColors from "./components/dictionaries/ProductColors.vue";
import DictionariesProductArrangements from "./components/dictionaries/ProductArrangements.vue";
import DictionariesProductFinishes from "./components/dictionaries/ProductFinishes.vue";
import DictionariesProductCollections from "./components/dictionaries/ProductCollections.vue";
import DictionariesProductCategories from "./components/dictionaries/ProductCategories.vue";
import DictionariesFileTypes from "./components/dictionaries/FileTypes.vue";
import DictionariesProductGroups from "./components/dictionaries/ProductGroups.vue";

// -- Files
import Files from "./components/Files.vue";
import FilesUpload from "./components/FilesUpload.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: {
        submenu: "dashboard",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPassword,
    },
    {
      path: "/activity-log",
      name: "activityLog",
      component: ActivityLog,
      meta: {
        submenu: "activity",
      },
    },
    {
      path: "/users-list",
      name: "usersList",
      component: UsersList,
      meta: {
        submenu: "users",
      },
    },
    {
      path: "/users-store",
      name: "usersStore",
      component: UsersStore,
      meta: {
        submenu: "users",
      },
    },
    {
      path: "/edit-account",
      name: "accountEdit",
      component: AccountEdit,
    },
    {
      path: "/products",
      name: "productsList",
      component: ProductsList,
      meta: {
        submenu: "products",
      },
    },
    {
      path: "/product/:id/edit/:lang",
      name: "productEdit",
      component: ProductEdit,
      meta: {
        submenu: "products",
      },
    },
    {
      path: "/products/import",
      name: "productsImport",
      component: ProductsImport,
      meta: {
        submenu: "products",
      },
    },
    {
      path: "/dictionaries",
      name: "dictionariesList",
      component: DictionariesList,
      meta: {
        submenu: "dictionaries",
        adminAuth: false,
      },
      children: [
        {
          path: "product-brands",
          name: "dictionariesProductBrands",
          component: DictionariesProductBrands,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-categories",
          name: "dictionariesProductCategories",
          component: DictionariesProductCategories,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-systems",
          name: "dictionariesProductSystems",
          component: DictionariesProductSystems,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-colors",
          name: "dictionariesProductColors",
          component: DictionariesProductColors,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-grups",
          name: "dictionariesProductGroups",
          component: DictionariesProductGroups,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-arrangement",
          name: "dictionariesProductArrangements",
          component: DictionariesProductArrangements,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-finishes",
          name: "dictionariesProductFinishes",
          component: DictionariesProductFinishes,
          meta: {
            submenu: "dictionaries",
          },
        },
        {
          path: "product-collections",
          name: "dictionariesProductCollections",
          component: DictionariesProductCollections,
          meta: {
            submenu: "dictionaries",
          },
        },
      ],
    },
    {
      path: "/files",
      name: "files",
      component: Files,
      meta: {
        submenu: "files",
      },
    },
    {
      path: "/files/upload",
      name: "filesUpload",
      component: FilesUpload,
      meta: {
        submenu: "files",
      },
    },
    {
      path: "file-types",
      name: "fileTypes",
      component: DictionariesFileTypes,
      meta: {
        submenu: "files",
      },
    },
    {
      path: "languages",
      name: "languages",
      component: DictionariesLanguages,
      meta: {
        submenu: "languages",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // try to access a restricted page + not logged in
  const publicPages = ["/login", "/reset-password"];
  const authRequired = !publicPages.includes(to.path);
  const user = localStorage.getItem("user");

  if (authRequired && !user) {
    return next("/login");
  } else if (user) {
    if (to.meta.adminAuth) {
      let userJson = JSON.parse(user);

      if (userJson.role != "admin") {
        return;
      }
    }

    // languages
    if (
      !store.state.language.all ||
      (store.state.language.all && !store.state.language.all.length)
    ) {
      store.dispatch("language/fetchLanguages");
    }

    // Change lang form param
    if (
      to.params.lang &&
      store.state.language.current &&
      to.params.lang != store.state.language.current
    ) {
      store
        .dispatch("language/setLanguage", {
          code: to.params.lang,
          preventReload: true,
        })
        .then(() => {
          return next();
        });
    } else if (!store.state.language.current) {
      store
        .dispatch("language/setLanguage", {
          code: "pl",
          preventReload: true,
        })
        .then(() => {
          return next();
        });
    } else {
      return next();
    }
  } else {
    return next();
  }
});
