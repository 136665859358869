<template>
    <div v-if="localProduct">

        <input type="file" ref="photosInput" @change="onImageChange" :disabled="isStoring || isChangingDefault" style="display: none;">

        <button class="button button--icon mb-3" @click="$refs.photosInput.click()" :disabled="isStoring || isChangingDefault">
            <icon v-if="!isStoring" icon="plus" icon-classes="button__icon button__icon--left"></icon>
            <div v-else class="loader button__icon button__icon--left"></div>
            <span class="button__text" v-text="(isStoring) ? 'Wysyłanie...' : 'Dodaj zdjęcie'"> Dodaj zdjęcie</span>
        </button>

        <div v-for="photo in localProduct.photos" :key="'photo_' + photo.id" class="product-photo">
            <img :src="getApiPath(photo.path)" />
            <div class="product-photo__actions d-flex align-items-center justify-content-end">
                <button class="button-icon button-icon--bg-white" @click="setDefaultPhoto(photo.id)"
                    :class="{'button-icon--success' : photo.default}" :disabled="isChangingDefault || photo.default == 1">
                    <icon v-if="!isChangingDefault" icon="check"/>
                    <div v-else class="loader loader--icon"></div>
                </button>
                <button class="button-icon button-icon--ml-10 button-icon--bg-white" :disabled="isStoring || isChangingDefault"
                    @click="deleteObject(photo)">
                    <icon icon="unlink" />
                </button>
            </div>
        </div>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie zdjecia produktu
            </div>

            <div slot="body">
                Czy na pewno chcesz usunąć powiązanie zdjęcia z produktem?
                <div class="product-photo mt-4">
                    <img :src="getApiPath(objectToDelete.path)" />
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmdeleteObject()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import axios from 'axios';
    import _ from 'lodash';
    import Icon from '../parts/Icon';
    import Modal from '../parts/Modal';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;

    export default {
        data() {
            return {
                localProduct: false,
                image: '',
                imagePath: '',
                isStoring: false,
                isChangingDefault: false,
                showModal: false,
                defaultSelected: false,
                objectToDelete: false
            }
        },
        components: {
            Icon,
            Modal
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        watch: {
            product: {
                handler(newVal) {
                    this.isLoaded = true;
                    this.localProduct = _.cloneDeep(newVal);
                },
                deep: true,
                immediate: false
            }
        },
        methods: {
            savePhoto() {
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                let vm = this;
                let formData = new FormData();

                vm.isStoring = true;

                formData.append('image', this.image);
                formData.append('product_id', this.$route.params.id);
                vm.$emit('storingProduct', true);

                axios.post(API_BASE_URL + '/products/photos', formData, config)
                    .then(function (response) {
                        vm.localProduct.photos = _.cloneDeep(response.data.photos);

                        vm.$emit('storingProduct', false);
                        vm.isStoring = false;

                        vm.$root.$emit('noticesBox', {
                            type: 'success',
                            message: 'Zdjęcie zostało zapisane.'
                        });
                    })
                    .catch(function (error) {
                        vm.$emit('storingProduct', false);
                        vm.isStoring = false;
                        vm.$root.$emit('noticesBox', error.response.data);
                    });

                this.$refs.photosInput.value = '';
            },
            onImageChange(e) {
                this.image = e.target.files[0];
                this.savePhoto();
            },
            getApiPath(path) {
                if (path) {
                    let timestamp = new Date().getTime();

                    return API_PHOTO_URL + path + '?' + timestamp;
                } else return require('../../img/default.png');
            },
            setDefaultPhoto(photoId) {
                let vm = this;
                vm.isChangingDefault = true;
                axios.post(API_BASE_URL + '/products/photos/default', {
                        product_id: vm.product.id,
                        photo_id: photoId
                    }).then(function (response) {
                        vm.localProduct.photos = _.cloneDeep(response.data.photos);

                        vm.$emit('storingProduct', false);
                        vm.isChangingDefault = false;

                        vm.$root.$emit('noticesBox', {
                            type: 'success',
                            message: 'Zmieniono zdjęcie główne.'
                        });
                    })
                    .catch(function (error) {
                        vm.$emit('storingProduct', false);
                        vm.isChangingDefault = false;
                        vm.$root.$emit('noticesBox', error.response.data);
                    });
            },
            async deleteObject(object) {
                this.showModal = true;
                this.objectToDelete = object;
            },
            async confirmdeleteObject() {
                let vm = this;
                this.showModal = false;
                await axios.delete(API_BASE_URL + '/products/photos/delete/' + this.objectToDelete.relation_id).then(function (response) {
                    vm.localProduct.photos = _.cloneDeep(response.data);

                    vm.$root.$emit('noticesBox', {
                        type: 'success',
                        message: 'Usunięto powiązanie.'
                    });

                })
                .catch(function (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                });
            }
        }
    }
</script>