<template>
  <div>
    <div class="content-top row">
      <div class="col-24 d-flex justify-content-between align-items-center">
        <div class="content-top__title-box">
          <h1 class="content-top__heading">Lista grup produktów</h1>
          <div class="content-top__subheading">
            {{ objects.length }}
            {{ objects.length | numeralsText("grupa", "grupy", "grup") }}
          </div>
        </div>
        <button class="button button--icon" @click="addObject()">
          <icon
            icon="plus"
            icon-classes="button__icon button__icon--left icon--white icon__plus--small"
          />
          <span class="button__text">Dodaj nową grupę</span>
        </button>
      </div>
    </div>

    <div class="content row">
      <div class="col-24">
        <table class="items-table">
          <thead class="items-table__thead">
            <tr class="items-table__tr">
              <th class="items-table__th">ID</th>
              <th class="items-table__th" style="min-width: 400px">Nazwa</th>
              <th class="items-table__th p-0 text-right">Akcje</th>
            </tr>
          </thead>

          <tbody class="items-table__tbody">
            <template v-for="object in objects">
              <tr class="items-table__tr" v-bind:key="object.id">
                <td class="items-table__td">
                  <div class="form-box__mobile-element">Id</div>
                  {{ object.id }}
                </td>
                <td class="items-table__td items-table__td--expand">
                  <div class="form-box__mobile-element">Nazwa</div>
                  {{ object.name }}
                </td>

                <td class="items-table__td items-table__td--button">
                  <div class="form-box__mobile-element">Akcje</div>
                  <div class="d-flex justify-content-end">
                    <button
                      class="button-icon"
                      @click.prevent="editObject(object)"
                    >
                      <icon icon="edit" />
                    </button>

                    <button
                      class="button-icon button-icon--ml-10"
                      @click="deleteObject(object)"
                    >
                      <icon icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal add -->
    <modal :show="showAddModal" size="lg" @close="showAddModal = !showAddModal">
      <div slot="header" class="notice-modal__header--title">
        <span>Dodawanie nowej grupy</span>
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24">
            <div class="product-form__title">Nazwa</div>
            <input
              class="product-form__input"
              type="text"
              v-model="object.name"
              required
            />
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showAddModal = false"
          :disabled="isStoring"
        >
          Anuluj
        </button>
        <button
          class="button button--small button--ml-10"
          @click="storeObject()"
          :disabled="isStoring"
          v-text="isStoring ? 'Zapisuję...' : 'Zapisz'"
        >
          Zapisz
        </button>
      </div>
    </modal>

    <!-- Modal edit -->
    <modal
      :show="showEditModal"
      size="lg"
      @close="showEditModal = !showEditModal"
    >
      <div slot="header" class="notice-modal__header--title">
        <span>Edycja grupy</span>
      </div>

      <div slot="body">
        <div class="row">
          <div class="col-24">
            <div class="product-form__title">Nazwa</div>
            <input
              class="product-form__input"
              type="text"
              v-model="object.name"
              required
            />
            <label class="product-form__title mt-4">Produkty: </label>
            <treeselect
              v-if="showEditModal && object.id"
              v-model="object.products"
              :normalizer="normalizer"
              :multiple="true"
              :async="true"
              :load-options="loadOptions"
              :default-options="true"
              placeholder="Wpisz minium trzy znaki aby wyszukać produkt"
            />
          </div>
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showEditModal = false"
          :disabled="isStoring"
        >
          Anuluj
        </button>
        <button
          class="button button--small button--ml-10"
          @click="updateObject()"
          :disabled="isStoring"
          v-text="isStoring ? 'Zapisuję...' : 'Zapisz'"
        >
          Zapisz
        </button>
      </div>
    </modal>

    <!-- Modal delete -->
    <modal
      :show="showDeleteModal"
      size="md"
      @close="showDeleteModal = !showDeleteModal"
    >
      <div slot="header" class="notice-modal__header--title">
        Usuwanie koloru
      </div>

      <div slot="body">
        <div>
          Czy na pewno chcesz usunąć kolor
          <strong>{{
            "#" + objectToDelete.id + " - " + objectToDelete.name
          }}</strong
          >?
        </div>
      </div>

      <div slot="footer" class="d-flex align-items-center">
        <button
          class="button button--small button--grey"
          @click="showDeleteModal = false"
        >
          Nie
        </button>
        <button
          class="button button--small button--ml-10"
          @click="confirmDeleteObject()"
        >
          Tak
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Icon from "./../parts/Icon";
import Modal from "./../parts/Modal";
import _ from "lodash";
import Treeselect from "@riophae/vue-treeselect";
import { ASYNC_SEARCH } from "@riophae/vue-treeselect";
const simulateAsyncOperation = (fn) => {
  setTimeout(fn, 2000);
};

export default {
  data() {
    return {
      objects: {},
      object: {
        products: [],
      },
      productsAll: [],
      showAddModal: false,
      showEditModal: false,
      showDeleteModal: false,
      isEditing: false,
      isStoring: false,
      products: [],
      objectToDelete: {},
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  components: {
    Icon,
    Modal,
    Treeselect,
  },
  async created() {
    this.getObjects();
  },
  methods: {
    loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH && searchQuery.length > 3) {
        simulateAsyncOperation(() => {
          let params = {
            simple_product: true,
            search: searchQuery,
          };
          axios
            .get(API_BASE_URL + "/products", {
              params: params,
            })
            .then((response) => {
              const options = response.data;
              callback(null, options);
            });
        });
      } else if (action === ASYNC_SEARCH) {
        const options = this.object.productsAll;
        callback(null, options);
      }
    },
    async storeObject() {
      let vm = this;

      this.isStoring = true;

      try {
        const response = await axios.post(
          API_BASE_URL + "/product-groups",
          vm.object
        );

        vm.objects = response.data;
        vm.object = {};
        vm.showAddModal = false;
        vm.isStoring = false;
        this.getObjects();
      } catch (error) {
        vm.isStoring = false;
        vm.$root.$emit("noticesBox", error.response.data);
      }
    },
    async updateObject() {
      let vm = this;

      this.isStoring = true;

      try {
        const response = await axios.put(
          API_BASE_URL + "/product-groups/" + vm.object.id,
          vm.object
        );

        vm.objects = response.data;
        vm.object = {};
        vm.showEditModal = false;
        vm.isStoring = false;
        this.getObjects();
      } catch (error) {
        vm.isStoring = false;
        vm.$root.$emit("noticesBox", error.response.data);
      }
    },
    async getObjects() {
      try {
        const response = await axios.get(API_BASE_URL + "/product-groups");
        this.objects = response.data;

        // products

        this.objects.forEach((object, index) => {
          let products = [];

          object.products.forEach((product) => {
            products.push(product.id);
          });
          this.objects[index].products.length
            ? this.$set(this.objects[index], "productsAll", [
                ...this.objects[index].products,
              ])
            : null;
          this.$set(this.objects[index], "products", [...products]);
        });
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
    editObject(object) {
      this.object = _.cloneDeep(object);
      this.showEditModal = true;
      this.isEditing = true;
    },
    addObject() {
      this.object = {};
      this.isEditing = false;
      this.showAddModal = true;
    },
    async deleteObject(object) {
      this.showDeleteModal = true;
      this.objectToDelete = object;
    },
    async confirmDeleteObject() {
      this.showDeleteModal = false;
      try {
        await axios.delete(
          API_BASE_URL + "/product-groups/" + this.objectToDelete.id
        );
        this.getObjects();
      } catch (error) {
        this.$root.$emit("noticesBox", error.response.data);
      }
    },
  },
};
</script>
