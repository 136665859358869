<template>

    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Import produktów</h1>

                    <div class="content-top__subheading" v-if="uploadedFile">
                        {{ uploadedFile.name }}
                    </div>
                </div>

                <div class="upload-button-container d-flex align-items-center">
                    <div v-if="selectedFile" class="content-top__subheading mr-3">
                        {{ selectedFile.name }}
                    </div>

                    <button v-if="!selectedFile" class="button button--upload" @click="$refs.fileUpload.click()"
                        :disabled="isImporting">
                        <div class="loader button__icon button__icon--left" v-if="isSending"></div>
                        <span v-text="(uploadedFile) ? 'Zmień plik' : 'Wybierz plik'">Wybierz plik</span>
                    </button>
                    <button v-else class="button button--upload" :disabled="isSending || isImporting"
                        @click.prevent="sendFile()">
                        <div class="loader button__icon button__icon--left" v-if="isSending"></div>
                        <span v-text="(isSending) ? 'Wysyłanie' : 'Wyślij plik'"></span>
                    </button>

                    <button class="button button--icon w-100 button--ml-10 d-none" @click.prevent="importProducts()"
                        :disabled="isImporting || forms.length < 1">
                        <div class="loader button__icon button__icon--left" v-if="isImporting"></div>
                        <icon v-else icon="export" icon-classes="button__icon button__icon--left" />
                        <span class="button__text" v-text="isImporting ? 'Importuję...' : 'Importuj'"></span>
                    </button>

                    <input ref="fileUpload" type="file" @change="addFile" multiple="false" style="display: none;" />
                </div>
            </div>
        </div>

        <div class="row content" v-if="forms.length > 0">
            <div class="col-24">

                <table class="items-table">
                    <thead>
                        <th class="items-table__th" v-for="(data,index) in formsHeader" :key="'th_' + index">
                            {{data }}
                        </th>
                        <th class="items-table__th pr-0 text-right">
                            Akcje
                        </th>
                    </thead>

                    <tbody>
                        <tr class="items-table__tr" v-for="(form,index) in forms" :key="'product_' + index">
                            <td class="items-table__td" v-for="(data,subindex) in form.data"
                                :key="'product_' + index + '_' + subindex">
                                {{ data }}
                            </td>
                            <td class="items-table__td pr-0">
                                <div class="d-flex justify-content-end">
                                    <button class="button-icon" @click="importProduct(form)" v-if="!form.completed">
                                        <icon icon="export" v-if="!form.isStoring"></icon>
                                        <div v-else class="loader loader--icon"></div>
                                    </button>

                                    <button class="button-icon button-icon--ml-10" v-if="!form.completed"
                                        :disabled="form.isStoring" @click="removeForm(form)">
                                        <icon icon="delete"></icon>
                                    </button>
                                    <button v-else class="button-icon button-icon--success button-icon--ml-10"
                                        @click="removeForm(form)">
                                        <icon icon="check"></icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>

        <div v-else class="content">
            Wgraj plik, aby rozpocząć.
        </div>

    </div>

</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './parts/Icon';

    export default {
        data() {
            return {
                fileData: false,
                isDragOver: false,
                isSending: false,
                isImporting: false,
                disableUpload: true,
                selectedFile: null,
                uploadedFile: null,
                forms: [],
                formsHeader: false
            }
        },
        components: {
            Icon
        },
        methods: {
            addFile(e) {

                if (e.target.files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    this.$root.$emit('noticesBox', {
                        type: 'error',
                        message: 'Import można wykonywać tylko z plików .xlsx'
                    });
                    this.selectedFile = null;
                } else {
                    this.disableUpload = false;
                    this.selectedFile = e.target.files[0];
                }

                this.$refs.fileUpload.value = '';

            },
            sendFile() {
                let formData = new FormData();
                let vm = this;
                vm.isSending = true;

                formData.append('file', vm.selectedFile);

                axios.post(API_BASE_URL + '/products/import',
                        formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(function (response) {
                        vm.isSending = false;
                        vm.uploadedFile = vm.selectedFile;
                        vm.selectedFile = null;
                        vm.forms = [];
                        vm.formsHeader = response.data.header;
                        response.data.rows.forEach((row) => {
                            let _row = {};
                            _row.completed = false;
                            _row.data = row;
                            vm.forms.push(_row);
                        });
                    })
                    .catch(function (error) {
                        vm.isSending = false;
                        vm.$root.$emit('noticesBox', error.response.data);
                    });

            },
            removeForm(form) {
                this.forms = this.forms.filter(f => {
                    return f != form;
                });
            },
            importProducts() {
                let vm = this;
                vm.isImporting = true;


                // mass import ...
                vm.$root.$emit('noticesBox', {
                    type: 'error',
                    message: 'Funkcja masowego importu jest wyłączona.'
                });
                vm.isImporting = false;
                // ... mass import

            },
            importProduct(form) {
                let vm = this;
                let thisForm = form;
                if(thisForm.completed) return;

                vm.isImporting = true;
                let data = JSON.parse(JSON.stringify(thisForm.data));

                thisForm.isStoring = true;

                if (!data.language) {
                    data.language = vm.$store.state.language.current;
                }

                axios.post(API_BASE_URL + '/products', data)
                    .then(() => {

                        vm.$root.$emit('noticesBox', {
                            type: 'success',
                            message: 'Poprawnie zaimportowano produkt.'
                        });

                        thisForm.completed = true;
                        vm.isImporting = false;
                    })
                    .catch(error => {
                        vm.isImporting = false;
                        thisForm.isStoring = false;
                        vm.$root.$emit('noticesBox', error.response.data);
                    })
            }
        }
    }
</script>