import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store/index";
import interceptorsSetup from "./helpers/axios-interceptor";
import ElementUI from "element-ui";
import { ElementTiptapPlugin } from "element-tiptap";
import "element-tiptap/lib/index.css";
// Styles
import "./scss/main.scss";

// Filters
import "./filters/pretty-bytes";
import "./filters/pretty-date";
import "./filters/numerals-text";
import "./filters/uppercase";
import "./filters/lowercase";

Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin, {
  lang: "pl", // see i18n
  spellcheck: false, // can be overwritten by editor prop
});
Vue.config.productionTip = false;

// Vue config
Vue.config.productionTip = false;

// Axios interceptors setup
interceptorsSetup();

// go VUE!
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
