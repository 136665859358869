<template>
    <div class="login">
        <form class="login__wrap" name="form" @submit.prevent="handleLogin">
            <div class="login__header">
                <div class="login__header--logo">
                    <img class="login__img login__img--spim" src="../img/spim.png" />
                    <img class="login__img login__img--elita" src="../img/brand.png" />
                </div>
                <div class="login__header--title">
                    <span>Zaloguj się do systemu</span>
                </div>
            </div>
            <transition name="transition-fade">
                <div class="alert" :class="{'alert-danger': !response.success , 'alert-success': response.success}"
                    role="alert" v-if="response">
                    {{response.message}}
                </div>
            </transition>
            <div class="login__form">
                <div class="login__form--wrap">
                    <label class="login__form--label" for="email">Email</label>
                    <input class="login__form--input" type="text" name="username" v-model="user.email" :readonly="loading" autocomplete="email" required/>
                </div>
                <div class="login__form--wrap">
                    <label class="login__form--label" for="password">Password</label>
                    <input class="login__form--input" :type="passwordType" name="password" v-model="user.password" :readonly="loading" autocomplete="password" required/>
                    <div class="login__form--icon-wrap" @click="togglePassword">
                        <icon v-if="this.passwordType == 'password'" icon="eye" />
                        <icon v-else icon="eye" icon-classes="icon--regular" />
                    </div>
                </div>
                <div class="login__form--wrap">
                    <div class="d-flex align-items-center flex-direction-row">
                        <label for="checkbox" class="checkbox">
                            <input type="checkbox" id="checkbox" class="checkbox__input" />
                            <span class="checkbox__checkmark"></span>
                        </label>
                        <span class="login__form--text">Zapamiętaj mnie</span>
                    </div>
                </div>
                <div class="login__form--wrap">
                    <button class="button" :disabled="loading">
                        <span>Zaloguj</span>
                    </button>
                </div>
                 <div class="login__form--wrap">
                    <div class="login__form--remind">
                    Nie pamiętasz hasła?
                        <router-link class="link" :to="{name: 'resetPassword'}">Zresetuj hasło</router-link>
                    </div>
                </div>
            </div>

            <div class="login__footer">
                <span class="login__footer--content">
                    powered by
                    <a href="https://mda.pl/" title="MDA" target="_blank">
                        <img class="site-footer__img" src="../img/mda.png" />
                    </a>
                </span>
            </div>
        </form>
    </div>
</template>

<script>
import User from '../models/user';
import Icon from './parts/Icon';

export default {
    data() {
        return {
            user: new User(),
            loading: false,
            response: false,
            passwordType: 'password'
        };
    },
    components: {
        Icon
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push({name:'dashboard'});
        }
    },
    methods: {
        handleLogin() {
            this.loading = true;
            
            this.$store.dispatch('auth/login', this.user).then(
                success => {
                    // success!
                    this.loading = false;
                    this.response = success;
                },
                error => {
                    // error
                    this.loading = false;
                    this.response = error;
                }
            );
        },
        togglePassword(){
            this.passwordType = ((this.passwordType == 'password') ? 'text' : 'password');
        }
    }
};
</script>