<template>
    <div v-if="localProduct">
        <div class="product-form row">
            <div class="col-24">
                <div class="product-form__header d-flex align-items-center">
                    <div :class="{ 'is-hidden' : isHidden }" @click.prevent="isHidden = !isHidden">
                        <icon icon="plus" v-if="!isHidden" icon-classes="icon__plus--large icon--black icon--rotated" />
                        <icon icon="plus" v-else icon-classes="icon__plus--large icon--black" />
                    </div>
                    <span class="product-form__header-title">Pliki</span>
                </div>
            </div>

            <transition name="transition-slide">
                <div class="col-24" v-if="!isHidden && connectedFiles.length > 0">

                    <table class="items-table">
                        <thead>
                            <tr class="items-table__tr">
                                <th></th>
                                <th class="items-table__th">Nazwa oryginalna</th>
                                <th class="items-table__th">Nazwa wyświetlana</th>
                                <th class="items-table__th">Typ</th>
                                <th class="items-table__th pr-0 text-right">Akcje</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="items-table__tr" v-for="file in connectedFiles" :key="file.id">
                                <td class="items-table__td items-table__td--icon">
                                    <div class="items-table__icon" v-if="file.type && file.type.icon"
                                        :style="{ backgroundImage: 'url(' + getApiPath(file.type.icon) + ')'}"></div>
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Nazwa oryginalna</div>
                                    {{ file.original_name }}
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Nazwa wyświetlana</div>
                                    {{ file.display_name }}
                                </td>
                                <td class="items-table__td items-table__td--nowrap">
                                    <div class="form-box__mobile-element">Typ</div>
                                    {{ (file.type) ? file.type.name : '' }}
                                </td>
                                <td class="items-table__td items-table__td--button">
                                    <div class="form-box__mobile-element">Akcje</div>
                                    <div class="d-flex justify-content-end">
                                        <button class="button-icon button-icon--ml-10"
                                            @click="connect(file.id, 'delete')">
                                            <icon icon="unlink" icon-classes="icon--grey" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-end pt-4">
                        <button class="button icon__icons-wrap button--grey" @click="toggleModal()">
                            <icon icon="link" icon-classes="button__icon button__icon--left icon--regular" />
                            <span>Powiąż</span>
                        </button>
                    </div>
                </div>
                <div v-if="!connectedFiles.length > 0 && !isHidden" class="col-24">
                    <div class="d-flex justify-content-start">
                        <table class="product-form__table">
                            <tr class="items-table__tr">
                                <td class="items-table__td">
                                    <span class="items-table__noitems">Brak pozycji.</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="d-flex justify-content-end pt-4">
                        <button class="button button--icon button--grey" @click="toggleModal()">
                            <icon icon="link" icon-classes="button__icon button__icon--left icon--regular" />
                            <span>Powiąż</span>
                        </button>
                    </div>
                </div>
            </transition>

        </div>

        <!-- Modal -->
        <modal :show="showModal" @close="showModal = !showModal;">
            <div slot="header" class="d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center w-100">
                <div>Powiąż pliki z produktem</div>

                <div class="simple-search simple-search--modal pt-4">
                    <input type="text" class="simple-search__input" @input="searchFiles()" placeholder="Wyszukaj..."
                        v-model="search">
                    <button class="simple-search__button" @click="getFiles()">
                        <icon icon="search" />
                    </button>
                </div>
            </div>

            <div slot="body">

                <div v-if="!loadingFiles">
                    <table class="items-table" v-if="files.data && files.data.length > 0">
                        <thead>
                            <tr class="items-table__tr">
                                <th></th>
                                <th class="items-table__th">Nazwa oryginalna</th>
                                <th class="items-table__th">Nazwa wyświetlana</th>
                                <th class="items-table__th">Typ</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="items-table__tr" v-for="file in files.data" :key="file.id">
                                <td class="items-table__td">
                                    <div class="items-table__icon" v-if="file.type && file.type.icon"
                                        :style="{ backgroundImage: 'url(' + getApiPath(file.type.icon) + ')'}"></div>
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Nazwa oryginalna</div>
                                    {{file.original_name}}
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Nazwa wyświetlana</div>
                                    {{file.display_name}}
                                </td>
                                <td class="items-table__td" style="white-space: nowrap;">
                                    <div class="form-box__mobile-element">Typ</div>
                                    {{ (file.type) ? file.type.name : '' }}
                                </td>
                                <td class="items-table__td items-table__td--button">
                                    <div class="form-box__mobile-element">Akcje</div>
                                    <div class="icon__icons-wrap d-flex justify-content-end">
                                        <button class="button-icon" v-if="connectedFilesIds.includes(file.id)"
                                            @click="connect(file.id, 'delete')">
                                            <icon icon="unlink" />
                                        </button>
                                        <button class="button-icon" v-else @click="connect(file.id, 'add')">
                                            <icon icon="link" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else>
                        Brak plików w bazie.
                    </div>

                    <pagination :pagination="files" @paginate="getFiles()" :offset="1"></pagination>
                </div>
                <div v-else class="d-flex align-items-center">
                    <div class="loader button__icon button__icon--left d-inline-block"></div>
                    <div>Trwa wczytywanie plików...</div>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Anuluj
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import Modal from './../parts/Modal';
    import axios from 'axios';
    import Icon from '../parts/Icon';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;
    import _ from 'lodash';

    import Pagination from './../parts/Pagination';

    export default {
        data() {
            return {
                isHidden: false,
                showModal: false,
                localProduct: false,
                files: {
                    loading: true
                },
                loadingFiles: true,
                connectedFiles: {},
                connectedFilesIds: [],
                search: '',
                order: 'desc',
                orderBy: 'created_at'
            }
        },
        components: {
            Modal,
            Pagination,
            Icon
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        watch: {
            product: {
                handler(newVal) {
                    this.isLoaded = true;
                    this.localProduct = _.cloneDeep(newVal);
                    this.connectedFiles = this.localProduct.files;
                    this.connectedFilesIds = [];
                    this.connectedFiles.forEach((file) => {
                        this.connectedFilesIds.push(file.id);
                    });
                },
                deep: true,
                immediate: false
            }
        },
        methods: {
            async getFiles() {
                this.loadingFiles = true;

                try {
                    var params = {
                        'page': this.files.current_page,
                        'orderBy': this.orderBy,
                        'order': this.order,
                        'perPage': 5,
                        'languageCode': this.$store.state.language.current
                    };

                    if (this.search != '') params.search = this.search;

                    const response = await axios.get(API_BASE_URL + '/files', {
                        params: params
                    });
                    this.files = response.data;
                    this.loadingFiles = false;
                } catch (error) {
                    this.loadingFiles = false;
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            searchFiles: _.debounce(function () {
                this.files.current_page = 1;
                this.getFiles();
            }, 400),
            toggleModal() {
                if (!this.files.data || !this.files.data.length) {
                    this.getFiles();
                }
                this.showModal = !this.showModal;
            },
            async connect(id, action = 'add') {
                // TODO - zapis na przycisk, a nie po zmianie
                this.$emit('storingProduct', true);
                try {
                    const response = await axios.post(API_BASE_URL + '/products/connect', {
                        product_id: this.$route.params.id,
                        product_connect_id: id,
                        relation_type: 'files',
                        action: action
                    });
                    this.connectedFiles = response.data.product.files;
                    this.connectedFilesIds = [];
                    this.connectedFiles.forEach((product) => {
                        this.connectedFilesIds.push(product.id);
                    });
                    this.$emit('storingProduct', false);
                    
                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: response.data.message
                    });
                    
                } catch (error) {
                    this.$emit('storingProduct', false);
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            getApiPath(path) {
                if (path) {
                    let timestamp = new Date().getTime();

                    return API_PHOTO_URL + path + '?' + timestamp;
                } else return require('../../iconfont/default-file-icon.svg');
            }
        }
    }
</script>