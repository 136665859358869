<template>
  <div
    class="submenu d-flex justify-content-between align-items-center"
    :class="{ 'is-opened': isOpened }"
  >
    <div class="submenu__wrap">
      <div class="submenu__content">
        <ul class="submenu__items">
          <template v-if="false && $route.meta.submenu == 'dashboard'">
            <li class="submenu__item">
              <a href="#" class="submenu__link">Dokumentacja API</a>
            </li>
          </template>

          <template v-if="$route.meta.submenu == 'files'">
            <li class="submenu__item">
              <router-link
                class="submenu__link"
                :class="{ 'is-current': $route.name == 'files' }"
                @click.native="isOpened = !isOpened"
                :to="{ name: 'files' }"
                >Lista plików
              </router-link>
            </li>
            <li class="submenu__item">
              <router-link
                class="submenu__link"
                :class="{ 'is-current': $route.name == 'filesUpload' }"
                @click.native="isOpened = !isOpened"
                :to="{ name: 'filesUpload' }"
                >Dodaj pliki
              </router-link>
            </li>
            <li class="submenu__item">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{ 'is-current': $route.name == 'fileTypes' }"
                :to="{ name: 'fileTypes' }"
                >Typy plików
              </router-link>
            </li>
          </template>

          <template v-if="$route.meta.submenu == 'products'">
            <li class="submenu__item">
              <router-link
                class="submenu__link"
                :class="{ 'is-current': $route.name == 'productsList' }"
                @click.native="isOpened = !isOpened"
                :to="{ name: 'productsList' }"
                >Lista produktów
              </router-link>
            </li>
            <li class="submenu__item">
              <a
                @click.prevent="$root.$emit('addProductModal', {})"
                class="submenu__link"
                >Dodaj produkt</a
              >
            </li>
            <li v-if="false" class="submenu__item">
              <router-link
                class="submenu__link"
                :class="{ 'is-current': $route.name == 'productsImport' }"
                @click.native="isOpened = !isOpened"
                :to="{ name: 'productsImport' }"
                >Import
              </router-link>
            </li>
          </template>

          <template v-if="$route.meta.submenu == 'dictionaries'">
            <li class="submenu__item submenu__item--dark">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{
                  'is-current': $route.name == 'dictionariesProductBrands',
                }"
                :to="{ name: 'dictionariesProductBrands' }"
                >Brandy
              </router-link>
            </li>
            <li class="submenu__item submenu__item--dark">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{
                  'is-current': $route.name == 'dictionariesProductCategories',
                }"
                :to="{ name: 'dictionariesProductCategories' }"
                >Kategorie
              </router-link>
            </li>
            <li class="submenu__item submenu__item--dark">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{
                  'is-current': $route.name == 'dictionariesProductSystems',
                }"
                :to="{ name: 'dictionariesProductSystems' }"
                >Systemy
              </router-link>
            </li>
            <li class="submenu__item submenu__item--dark">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{
                  'is-current': $route.name == 'dictionariesProductColors',
                }"
                :to="{ name: 'dictionariesProductColors' }"
                >Kolory
              </router-link>
            </li>
            <li class="submenu__item submenu__item--dark">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{
                  'is-current': $route.name == 'dictionariesProductFinishes',
                }"
                :to="{ name: 'dictionariesProductFinishes' }"
                >Wykończenia
              </router-link>
            </li>
            <li class="submenu__item submenu__item--dark">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{
                  'is-current':
                    $route.name == 'dictionariesProductArrangements',
                }"
                :to="{ name: 'dictionariesProductArrangements' }"
                >Aranżacje
              </router-link>
            </li>
            <li class="submenu__item submenu__item--dark">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{
                  'is-current': $route.name == 'dictionariesProductCollections',
                }"
                :to="{ name: 'dictionariesProductCollections' }"
                >Kolekcje
              </router-link>
            </li>
            <li class="submenu__item submenu__item--dark">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{
                  'is-current': $route.name == 'dictionariesProductGroups',
                }"
                :to="{ name: 'dictionariesProductGroups' }"
                >Grupy
              </router-link>
            </li>
          </template>

          <template v-if="$route.meta.submenu == 'languages'">
            <li class="submenu__item">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{ 'is-current': $route.name == 'languages' }"
                :to="{ name: 'languages' }"
                >Lista języków
              </router-link>
            </li>
          </template>

          <template v-if="$route.meta.submenu == 'activity'">
            <li class="submenu__item">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{ 'is-current': $route.name == 'activityLog' }"
                :to="{ name: 'activityLog' }"
                >Lista aktywności użytkowników
              </router-link>
            </li>
          </template>

          <template v-if="$route.meta.submenu == 'users'">
            <li class="submenu__item">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{ 'is-current': $route.name == 'usersList' }"
                :to="{ name: 'usersList' }"
                >Lista użytkowników
              </router-link>
            </li>
            <li class="submenu__item">
              <router-link
                class="submenu__link"
                @click.native="isOpened = !isOpened"
                :class="{ 'is-current': $route.name == 'usersStore' }"
                :to="{ name: 'usersStore' }"
                >Dodaj użytkownika
              </router-link>
            </li>
          </template>
        </ul>

        <div class="submenu__mobile-close" @click="isOpened = !isOpened">
          <icon icon="plus" icon-classes="submenu__mobile-close-icon" />
        </div>

        <div class="submenu__language-switcher">
          <language-switcher
            :key="$route.params.id"
            v-if="
              !hideLanguageSwitcher.includes($route.name) &&
              !hideLanguageSwitcher.includes($route.meta.submenu)
            "
          ></language-switcher>
          <img class="submenu__logo" src="../../img/brand.png" />
        </div>
      </div>
    </div>

    <!--mobile-->
    <div class="submenu__mobile">
      <img class="submenu__logo" src="../../img/brand.png" />
    </div>
    <div class="submenu__mobile">
      <div
        class="mobile-menu__burger mobile-menu__burger--submenu"
        :class="{ close: isOpened }"
        @click="isOpened = !isOpened"
      >
        <div
          class="mobile-menu__burger-line mobile-menu__burger-line--submenu"
        ></div>
        <div
          class="mobile-menu__burger-line mobile-menu__burger-line--submenu"
        ></div>
        <div
          class="mobile-menu__burger-line mobile-menu__burger-line--submenu"
        ></div>
      </div>
    </div>
    <!--mobile-->
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/parts/LanguageSwitcher";
import Icon from "@/components/parts/Icon";

export default {
  data() {
    return {
      hideLanguageSwitcher: [
        "productEdit",
        "languages",
        "activityLog",
        "dictionariesProductBrands",
      ],
      isOpened: false,
    };
  },
  components: {
    LanguageSwitcher,
    Icon,
  },
};
</script>
