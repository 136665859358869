<template>
    <modal :show="showModal" @close="closeModal()" size="md">
        <div slot="header" class="d-flex justify-content-between align-items-center w-100" v-text="isStored ? 'Produkt dodany' : 'Dodaj nowy produkt'"></div>

        <div slot="body">

            <div class="col-24" v-if="!isStored">
                <div class="row">
                    <div class="col-24">
                        <div class="product-form__title">Nazwa</div>
                        <input class="product-form__input" type="text" v-model="newProduct.name" @keypress.enter.prevent="storeProduct()" required/>
                    </div>
                    <div class="col-24 mt-4">
                        <div class="product-form__title">Indeks</div>
                        <input class="product-form__input" type="text" v-model="newProduct.index" @keypress.enter.prevent="storeProduct()" required/>
                    </div>
                </div>
            </div>
            <div v-else>
                Czy chcesz przejść do ekranu edycji produktu <strong>{{ isStored.name }}</strong>?
            </div>

        </div>

        <div slot="footer" class="d-flex align-items-center">
            <button class="button button--grey" @click="closeModal()" :disabled="isStoring">
                Anuluj
            </button>
            <button v-if="!isStored" class="button button--icon button--ml-10" @click="storeProduct()" :disabled="isStoring">
                <div class="loader button__icon button__icon--left" v-if="isStoring"></div>
                <icon v-else icon="plus" icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                <span class="button__text">Dodaj nowy produkt</span>
            </button>
            <div v-else @click="goToEditProduct()" class="button button--icon button--ml-10">
                <icon icon="edit" icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                <span class="button__text">Przejdź do edycji</span>
            </div>
        </div>

    </modal>
</template>

<script>
    import Modal from './Modal';
    import Icon from './Icon';
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

    export default {
        mounted() {
            this.$root.$on('addProductModal', (data) => {
                this.showModal = true;
                this.modalData = data;
            });
        },
        data() {
            return {
                showModal: false,
                modalData: {},
                newProduct: {},
                isStoring: false,
                isStored: false
            };
        },
        components: {
            Modal,
            Icon
        },
        beforeDestroy() {
            this.$root.$off('addProductModal');
        },
        methods: {
            async storeProduct() {

                let data = {};
                this.isStoring = true;

                if (this.newProduct.name) data.name = this.newProduct.name;
                if (this.newProduct.index) data.index = this.newProduct.index;
                data.status = "0";

                axios.post(API_BASE_URL + '/products', data)
                    .then(response => {

                        this.$root.$emit('noticesBox', {
                            type: 'success',
                            message: 'Poprawnie dodano nowy produkt.'
                        });

                        this.newProduct = {};
                        this.isStoring = false;
                        this.isStored = response.data;
                        this.$root.$emit('addProductModalSuccess');
                    })
                    .catch(error => {
                        this.isStoring = false;

                        this.$root.$emit('noticesBox', error.response.data);
                    })

            },
            closeModal() {
                this.showModal = false;
                this.newProduct = {};
                this.isStoring = false;
                this.isStored = false;
                this.modalData = {};
            },
            goToEditProduct() {
                let product = this.isStored;
                let vm = this;
                this.closeModal();
                if (typeof product != 'undefined' && product.id) {
                    vm.$router.push({
                        name: 'productEdit',
                        params: {
                            id: product.id,
                            lang: vm.$store.state.language.current
                        }
                    });
                }
            }
        }
    }
</script>